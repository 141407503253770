import React, {useEffect, useState} from "react";
import MotherlinkEditModal from '../components/MotherlinkEditModal';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import ConfirmDialog from '../components/ConfirmDialog';
import {Snowflake} from "@theinternetfolks/snowflake";
import '../assets/css/styles.css';
import {DynuModal, openDynuModal, updateMotherLink} from "../components/DynuModal";

const BASIC_AUTH = 'Basic ' + btoa('admin:20afFjskd93VXl');

const getDropdownContainer = () => {
    return document.getElementById('dropdown-container') || document.body;
};

export default function Users() {
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [clickPosition, setClickPosition] = useState(null);
    const [users, setUsers] = useState([]);
    const [refresh, setRefresh] = useState();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [motherlinks, setMotherlinks] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({isOpen: false, message: "", id: null, callback: null});
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [newUser, setNewUser] = useState({
        name: '',
        wechat: '',
        email: '',
        mother_link_id: '',
        user_link: '',
        expire_date: ''
    });
    const [currentMotherlink, setCurrentMotherlink] = useState(null);
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const [editUser, setEditUser] = useState({
        id: '',
        name: '',
        wechat: '',
        email: '',
        mother_link_id: '',
        user_link: '',
        expire_date: ''
    });
    const [editMotherlinkModalOpen, setEditMotherlinkModalOpen] = useState(false);
    const [editMotherlink, setEditMotherlink] = useState({
        id: '',
        name: '',
        provider: '',
        url: '',
        email: ''
    });
    const [searchTerm, setSearchTerm] = useState('');

    const [dynuModalOpen, setDynuModalOpen] = useState(false);
    const [selectedDynuDomain, setSelectedDynuDomain] = useState('');
    const [dynuDomainList, setDynuDomainList] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    const toggleDropdown = (userId, e) => {
        e.stopPropagation();
        setDropdownOpen((prevState) => ({
            ...prevState,
            [userId]: !prevState[userId],
        }));
    };

    const copyToClipboard = (text, event) => {
        event.stopPropagation();
        const link = text.startsWith('http://') || text.startsWith('https://') ? text : `http://${text}`;

        navigator.clipboard.writeText(link).then(() => {
            const {clientX, clientY} = event;
            setClickPosition({x: clientX, y: clientY});
            setTimeout(() => setClickPosition(null), 1000);
        });
    };

    const handleMotherlinkSelect = (userId, motherlinkId, event) => {
        event.stopPropagation();
        const queryParams = new URLSearchParams({id: userId, mother_link_id: motherlinkId}).toString();

        fetch(`https://vpn.intercode.se/user.php?action=update&${queryParams}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        const updatedUser = {
                            ...users.find(user => user.id === userId),
                            mother_link_id: motherlinkId
                        };
                        setUsers(users.map(tuser => tuser.id === userId ? updatedUser : tuser));
                        setFilteredUsers(filteredUsers.map(tuser => tuser.id === userId ? updatedUser : tuser));
                        updateMotherLink([updatedUser], motherlinks.find(motherlink => motherlink.id === motherlinkId));
                    } else {
                        console.error('Error updating user:', data.error);
                    }
                })
                .catch(error => console.error('Error updating user:', error));
    };

    const handleDelete = (id) => {
        console.log("Test delete:", id);
        fetch(`https://vpn.intercode.se/user.php?action=delete&id=${id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        window.location.reload();
                    } else {
                        console.error('Error deleting user:', data.error);
                    }
                })
                .catch(error => console.error('Error deleting user:', error));
    };

    const confirmDelete = (id, e, message = "Are you sure you want to delete this user?", callback = null) => {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true,
            id,
            message,
            callback: () => {
                if (callback) {
                    callback();
                }
            }
        });
    };

    const handleDynuSelect = (updatedUser) => {
        console.log("Updated user", updatedUser);
        setSelectedDynuDomain('');
        setDynuModalOpen(false);
        setUsers(users.map(user => user.id === updatedUser.id ? updatedUser : user));
        setFilteredUsers(filteredUsers.map(user => user.id === updatedUser.id ? updatedUser : user));
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNewUser({...newUser, [name]: value});
    };

    const handleAddUser = () => {
        const userId = "" + Snowflake.generate();
        const queryParams = new URLSearchParams({...newUser, id: userId}).toString();
        fetch(`https://vpn.intercode.se/user.php?action=add&${queryParams}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setAddUserModalOpen(false);
                        window.location.reload();
                    } else {
                        console.error('Error adding user:', data.error);
                    }
                })
                .catch(error => console.error('Error adding user:', error));
    };

    const handleRowClick = (user) => {
        setEditUser(user);
        setEditUserModalOpen(true);
    };

    const handleEditInputChange = (e) => {
        const {name, value} = e.target;
        setEditUser({...editUser, [name]: value});
    };

    const handleUpdateUser = () => {
        const queryParams = new URLSearchParams(editUser).toString();
        fetch(`https://vpn.intercode.se/user.php?action=update&${queryParams}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setUsers(users.map(user => user.id === editUser.id ? editUser : user));
                        setFilteredUsers(filteredUsers.map(user => user.id === editUser.id ? editUser : user));
                        setEditUserModalOpen(false);
                    } else {
                        console.error('Error updating user:', data.error);
                    }
                })
                .catch(error => console.error('Error updating user:', error));
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        setFilteredUsers(users.filter(user => {
            const motherlink = motherlinks.find(motherlink => motherlink.id === user.mother_link_id);
            const motherlinkMatches = motherlink && Object.values(motherlink).some(field =>
                    field && field.toString().toLowerCase().includes(value)
            );
            return Object.values(user).some(field =>
                    field && field.toString().toLowerCase().includes(value)
            ) || motherlinkMatches;
        }));
    };

    useEffect(() => {
        fetch('https://vpn.intercode.se/user.php?action=find_all')
                .then(response => response.json())
                .then(data => {
                    setUsers(data);
                    setFilteredUsers(data);
                })
                .catch(error => console.error('Error fetching users:', error));
    }, []);

    useEffect(() => {
        fetch('https://vpn.intercode.se/motherlink.php?action=find_all')
                .then(response => response.json())
                .then(data => setMotherlinks(data))
                .catch(error => console.error('Error fetching motherlinks:', error));
    }, []);

    const getMotherlinkNameById = (id) => {
        const motherlink = motherlinks.find(motherlink => motherlink.id === id);
        return motherlink ? motherlink.name : 'Unknown';
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleUpdateUser();
        }
    };

    return (
            <>

                <DynuModal isOpen={dynuModalOpen} toggle={() => setDynuModalOpen(false)} onSelect={handleDynuSelect}
                           dynuDomainList={dynuDomainList} user={selectedUser} motherlinks={motherlinks} />

                <MotherlinkEditModal
                        isOpen={editMotherlinkModalOpen}
                        toggle={() => setEditMotherlinkModalOpen(false)}
                        motherlink={currentMotherlink}
                        isEdit={true}
                        onSave={(updatedMotherlink, urlChanged) => {
                            if (urlChanged) {
                                updateMotherLink(users, updatedMotherlink);
                            }
                            setMotherlinks(motherlinks.map(ml => ml.id === updatedMotherlink.id ? updatedMotherlink : ml));
                            setEditMotherlinkModalOpen(false);
                        }}
                />
                <Modal isOpen={addUserModalOpen} toggle={() => setAddUserModalOpen(false)}>
                    <ModalHeader toggle={() => setAddUserModalOpen(false)}>Add User</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" value={newUser.name}
                                       onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="wechat">Wechat</Label>
                                <Input type="text" name="wechat" id="wechat" value={newUser.wechat}
                                       onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" value={newUser.email}
                                       onChange={handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="mother_link_id">Motherlink</Label>
                                <Input
                                        type="select"
                                        name="mother_link_id"
                                        id="mother_link_id"
                                        value={newUser.mother_link_id} // Correctly bind the value to the state
                                        onChange={handleInputChange} // Ensure the onChange handler is set
                                >
                                    {motherlinks.map(motherlink => (
                                            <option key={motherlink.id} value={motherlink.id}>{motherlink.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <Label for="user_link">Userlink</Label>*/}
                            {/*    <Input type="text" name="user_link" id="user_link" value={newUser.user_link}*/}
                            {/*           onChange={handleInputChange} />*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <Label for="expire_date">Expire Date</Label>
                                <Input type="date" name="expire_date" id="expire_date" value={newUser.expire_date}
                                       onChange={handleInputChange} />
                            </FormGroup>
                            <ModalFooter>
                                <Button color="primary" onClick={handleAddUser}>Add User</Button>
                                <Button color="secondary" onClick={() => setAddUserModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </Form>
                    </ModalBody>
                </Modal>

                <Modal isOpen={editUserModalOpen} toggle={() => setEditUserModalOpen(false)}>
                    <ModalHeader toggle={() => setEditUserModalOpen(false)}>Edit User</ModalHeader>
                    <ModalBody>
                        <Form onKeyPress={handleKeyPress}>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" value={editUser.name}
                                       onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="wechat">Wechat</Label>
                                <Input type="text" name="wechat" id="wechat" value={editUser.wechat}
                                       onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" value={editUser.email}
                                       onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="mother_link_id">Motherlink</Label>
                                <Input
                                        type="select"
                                        name="mother_link_id"
                                        id="mother_link_id"
                                        value={editUser.mother_link_id} // Correctly bind the value to the state
                                        onChange={handleEditInputChange} // Ensure the onChange handler is set
                                >
                                    {motherlinks.map(motherlink => (
                                            <option key={motherlink.id} value={motherlink.id}>{motherlink.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <Label for="user_link">Userlink</Label>*/}
                            {/*    <Input type="text" name="user_link" id="user_link" value={editUser.user_link}*/}
                            {/*           onChange={handleEditInputChange} />*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <Label for="expire_date">Expire Date</Label>
                                <Input type="date" name="expire_date" id="expire_date" value={editUser.expire_date}
                                       onChange={handleEditInputChange} />
                            </FormGroup>
                            <ModalFooter>
                                <Button color="primary" onClick={handleUpdateUser}>Update User</Button>
                                <Button color="secondary" onClick={() => setEditUserModalOpen(false)}>Cancel</Button>
                            </ModalFooter>
                        </Form>
                    </ModalBody>
                </Modal>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <CardTitle tag="h4">Users</CardTitle>
                                        <Button color="success" onClick={() => setAddUserModalOpen(true)}>+</Button>
                                    </div>
                                    <Input
                                            type="text"
                                            placeholder="Search users..."
                                            value={searchTerm}
                                            onChange={handleSearch}
                                            style={{marginTop: '10px'}}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <div className="table-container mytable">
                                        <Table className="tablesorter">
                                            <thead className="text-primary">
                                            <tr>
                                                <th>Expire Date</th>
                                                <th>Wechat</th>
                                                <th>Motherlink</th>
                                                <th>Dynu</th>
                                                {/*<th>Userlink</th>*/}
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filteredUsers.map(user => (
                                                    <tr key={user.id} onClick={() => handleRowClick(user)}>
                                                        <td className="text-nowrap">{user.expire_date}</td>
                                                        <td>{user.wechat}</td>
                                                        <td>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <Dropdown
                                                                        isOpen={dropdownOpen[user.id] || false}
                                                                        toggle={(e) => toggleDropdown(user.id, e)}
                                                                >
                                                                    <DropdownToggle className="px-3" caret>
                                                                        {getMotherlinkNameById(user.mother_link_id)}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu container={getDropdownContainer()}
                                                                                  className="no-transition">
                                                                        {motherlinks.map(motherlink => (
                                                                                <DropdownItem key={motherlink.id}
                                                                                              onClick={(e) => handleMotherlinkSelect(user.id, motherlink.id, e)}>
                                                                                    {motherlink.name}
                                                                                </DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                                <Button color="info" size="sm"
                                                                        className="ml-1 py-2 px-2"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            const motherlink = motherlinks.find(ml => ml.id === user.mother_link_id);
                                                                            setCurrentMotherlink(motherlink);
                                                                            setEditMotherlinkModalOpen(true);
                                                                        }}>
                                                                    <i className="tim-icons icon-pencil"></i>
                                                                </Button>
                                                                <Button color="info" size="sm"
                                                                        className="ml-1 py-2 px-2 copy-button"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            const motherlink = motherlinks.find(ml => ml.id === user.mother_link_id);
                                                                            copyToClipboard(motherlink.url, e);
                                                                        }}>
                                                                    <i className="tim-icons icon-link-72"></i>
                                                                </Button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>

                                                                <Button color="primary" size="sm"
                                                                        className="ml-1 py-2 px-2"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setSelectedUser(user);
                                                                            openDynuModal(setDynuDomainList, setDynuModalOpen);
                                                                        }}>
                                                                    <i className="tim-icons icon-simple-add"></i>
                                                                </Button>
                                                                {user.user_link ? <Button color="primary" size="sm"
                                                                                          className="ml-1 py-2 px-2 copy-button"
                                                                                          onClick={(e) => {
                                                                                              e.stopPropagation();
                                                                                              copyToClipboard(user.user_link, e);
                                                                                          }}>
                                                                    <i className="tim-icons icon-link-72"></i>
                                                                </Button> : <></>}
                                                            </div>
                                                        </td>


                                                        <td>
                                                            <Button color="danger" className="small-trash-button"
                                                                    onClick={(e) => confirmDelete(user.id, e, "Are you sure you want to delete this user?", () => handleDelete(user.id))}>
                                                                <i className="tim-icons icon-trash-simple"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                        {clickPosition && (
                                                <div
                                                        className="overlay"
                                                        style={{
                                                            top: clickPosition.y,
                                                            left: clickPosition.x,
                                                        }}
                                                >
                                                    Link copied!
                                                </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <ConfirmDialog
                        isOpen={confirmDialog.isOpen}
                        toggle={() => setConfirmDialog({isOpen: false, message: "", id: null, callback: null})}
                        message={confirmDialog.message}
                        callback={confirmDialog.callback}
                />
            </>
    );
}