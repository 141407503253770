/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import TableList from "views/TableList.js";
import Users from "./views/Users";
import Motherlinks from "./views/Motherlinks";

var routes = [
  {
    path: "",
    name: "Users",
    rtlName: "",
    icon: "tim-icons icon-badge",
    component: <Users />,
    layout: "",
  },
  {
    path: "/motherlinks",
    name: "Motherlinks",
    rtlName: "",
    icon: "tim-icons  icon-link-72",
    component: <Motherlinks />,
    layout: "",
  },
];
export default routes;
