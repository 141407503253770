import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ConfirmDialog = ({ isOpen, toggle, id, message, callback, handleDelete }) => {
    const [dialogMessage, setDialogMessage] = useState(message);
    const [dialogCallback, setDialogCallback] = useState(callback);

    useEffect(() => {
        setDialogMessage(message);
        setDialogCallback(() => callback);
    }, [message, callback]);

    const handleConfirm = () => {
        if (dialogCallback) {
            dialogCallback();
        } else if (handleDelete && id) {
            handleDelete(id);
        }
        toggle();
    };

    return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirm Action</ModalHeader>
                <ModalBody>
                    {dialogMessage}
                    <br />
                    <br />
                    <div className="d-flex justify-content-between">
                        <Button color="danger" onClick={handleConfirm}>Delete</Button>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </div>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>
    );
};

export default ConfirmDialog;