import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';

const API_KEY = "5dg56e5b7b643ceUe34ggT5b7343Tg43";

const generateNodeName = (wechat) => {
    const cleanedWechat = wechat.replace(/[^a-z]/gi, '').toLowerCase();
    const randomNumber = Math.floor(Math.random() * 900) + 100; // Ensures the number is between 100 and 1000
    return `${cleanedWechat}${randomNumber}`;
};

const getMotherlinkUrlById = (id, motherlinks) => {
    const motherlink = motherlinks.find(motherlink => motherlink.id === id);
    return motherlink ? motherlink.url : 'https://www.google.com';
};

const createWebRedirect = (dynu, user, motherlinks) => {
    const nodeName = generateNodeName(user.wechat);
    const motherlinkUrl = getMotherlinkUrlById(user.mother_link_id, motherlinks);
    const payload = {
        nodeName: nodeName,
        redirectType: "UF",
        state: true,
        url: motherlinkUrl,
        cloak: false,
        includeQueryString: true,
        redirect301: false
    };

    return fetch(`https://api.dynu.com/v2/dns/${dynu.id}/webredirect`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "API-Key": API_KEY
        },
        body: JSON.stringify(payload)
    })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(errorBody => {
                        console.error('Error creating web redirect:', errorBody);
                        throw new Error('Error creating web redirect: ' + errorBody);
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log("Web redirect created:", data);
                return data;
            })
            .catch(error => {
                console.error("Error creating web redirect:", error);
                throw error;
            });
};

const deleteWebRedirect = (dynu, user) => {
    if (!user.user_link_id) {
        return Promise.resolve();
    }

    console.log("Deleting webrquest for user", user);

    return fetch(`https://api.dynu.com/v2/dns/${user.domain_id}/webRedirect/${user.user_link_id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "API-Key": API_KEY
        }
    })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(errorBody => {
                        console.error('Failed to delete web redirect:', errorBody);
                        throw new Error('Failed to delete web redirect: ' + errorBody);
                    });
                }
                console.log("Web redirect deleted");
            })
            .catch(error => {
                console.error("Error deleting web redirect:", error);
                // Continue even if deletion fails
            });
};

const updateUser = (user, data) => {
    console.log("Updating user with data: ", data);
    const queryParams = new URLSearchParams({
        id: user.id,
        user_link: data.hostname,
        user_link_id: data.id,
        domain_id: "" + data.domainId // Include domain_id in the queryParams
    }).toString();
    return fetch(`https://vpn.intercode.se/user.php?action=update&${queryParams}`)
            .then(response => {
                if (!response.ok) {
                    return response.text().then(errorBody => {
                        console.error('Error updating user link and user link ID:', errorBody);
                        throw new Error('Error updating user link and user link ID: ' + errorBody);
                    });
                }
                return response.json();
            })
            .then(updateData => {
                console.log("User link and user link ID updated:", updateData);
                return { ...user, user_link: data.hostname, user_link_id: data.id, domain_id: data.domainId }; // Return updated user data
            })
            .catch(error => {
                console.error("Error updating user link and user link ID:", error);
                throw error;
            });
};

const handleDynuSelect = (dynu, user, motherlinks, onSelect, toggle, setLoading) => {
    setLoading(true);
    createWebRedirect(dynu, user, motherlinks)
            .then(data => {
                return deleteWebRedirect(dynu, user).then(() => data);
            })
            .then(data => {
                return updateUser(user, data);
            })
            .then(updatedUser => {
                onSelect(updatedUser); // Pass the updated user data to the callback
                toggle(false);
            })
            .catch(error => {
                console.error("Error handling Dynu select:", error);
                alert("An error occurred: " + error.message); // Display error message popup
            })
            .finally(() => {
                setLoading(false);
            });
};

const DynuModal = ({ isOpen, toggle, onSelect, dynuDomainList, user, motherlinks }) => {
    const [isLoading, setLoading] = useState(false);

    return (
            <Modal isOpen={isOpen} toggle={toggle} backdrop={true}>
                <ModalHeader toggle={toggle}>Select Dynu Domain</ModalHeader>
                <ModalBody>
                    {isLoading && (
                            <div className="loader-overlay">
                                <Spinner color="gray" style={{ width: '3rem', height: '3rem' }} />
                            </div>
                    )}
                    {dynuDomainList.map(dynu => (
                            <Button key={dynu.id} onClick={() => handleDynuSelect(dynu, user, motherlinks, onSelect, toggle, setLoading)}>
                                {dynu.name}
                            </Button>
                    ))}
                </ModalBody>
            </Modal>
    );
};


const userLinkToNodeName = (userLink) => {
    return userLink.split('.')[0];
};

const updateMotherLink = async (users, motherlink) => {
    try {
        // Filter and update users
        const updatePromises = users
                .filter(user => user.mother_link_id === motherlink.id && user.user_link_id)
                .map(async user => {
                    const nodeName = userLinkToNodeName(user.user_link);
                    const payload = {
                        nodeName: nodeName,
                        redirectType: "UF",
                        state: true,
                        url: motherlink.url,
                        cloak: false,
                        includeQueryString: true,
                        redirect301: false
                    };

                    try {
                        const response = await fetch(`https://api.dynu.com/v2/dns/${user.domain_id}/webRedirect/${user.user_link_id}`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "API-Key": API_KEY
                            },
                            body: JSON.stringify(payload)
                        });

                        if (!response.ok) {
                            const errorBody = await response.text();
                            throw new Error(errorBody);
                        }

                        const data = await response.json();
                        console.log("Web redirect updated for user:", user.id, data);
                        return { wechat: user.wechat, status: 'OK' };
                    } catch (error) {
                        console.error("Error updating web redirect for user:", user.id, error);
                        return { wechat: user.wechat, status: 'FAILED', error: error.message };
                    }
                });

        const results = await Promise.all(updatePromises);

        const failedUpdates = results.filter(result => result.status === 'FAILED');
        if (failedUpdates.length > 0) {
            const feedback = failedUpdates.map(result => `${result.wechat} - ${result.status}: ${result.error}`).join('\n');
            alert(`Some updates failed:\n${feedback}`);
        } else {
            // const feedback = results.map(result => `${result.wechat} - ${result.status}`).join('\n');
            // alert(feedback);
        }
    } catch (error) {
        console.error("Error in updateMotherLink:", error);
        alert("An error occurred: " + error.message);
    }
};

const openDynuModal = (setDynuDomainList, toggle) => {
    fetch('https://api.dynu.com/v2/dns', {
        headers: {
            "Content-Type": "application/json",
            "API-Key": API_KEY
        }
    })
            .then(response => response.json())
            .then(data => {
                setDynuDomainList(data.domains);
                toggle(true);
            })
            .catch(error => {
                console.error("Error fetching dynu domains:", error);
            });
};

export { DynuModal, openDynuModal, updateMotherLink };