import React, { useState, useEffect } from "react";
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { Snowflake } from "@theinternetfolks/snowflake";

const MotherlinkEditModal = ({ isOpen, toggle, motherlink, isEdit, onSave }) => {
    const [newMotherlink, setNewMotherlink] = useState(motherlink || {});

    useEffect(() => {
        setNewMotherlink(motherlink || {});
    }, [motherlink]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewMotherlink({ ...newMotherlink, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEdit) {
            const queryParams = new URLSearchParams(newMotherlink).toString();
            fetch(`https://vpn.intercode.se/motherlink.php?action=update&${queryParams}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            onSave(newMotherlink, data.motherLinkChanged);
                            toggle();
                        } else {
                            console.error('Error updating motherlink:', data.error);
                        }
                    })
                    .catch(error => console.error('Error updating motherlink:', error));
        } else {
            const motherlinkId = "" + Snowflake.generate();
            const queryParams = new URLSearchParams({ ...newMotherlink, id: motherlinkId }).toString();
            fetch(`https://vpn.intercode.se/motherlink.php?action=add&${queryParams}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            onSave({ ...newMotherlink, id: motherlinkId });
                            toggle();
                        } else {
                            console.error('Error creating motherlink:', data.error);
                        }
                    })
                    .catch(error => console.error('Error creating motherlink:', error));
        }
    };

    return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>{isEdit ? 'Edit Motherlink' : 'Create New Motherlink'}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input type="text" name="name" id="name" value={newMotherlink.name || ''} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="provider">Provider</Label>
                            <Input type="text" name="provider" id="provider" value={newMotherlink.provider || ''} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="url">URL</Label>
                            <Input type="text" name="url" id="url" value={newMotherlink.url || ''} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" value={newMotherlink.email || ''} onChange={handleChange} required />
                        </FormGroup>
                        <ModalFooter>
                            <Button color="primary" type="submit">{isEdit ? 'Update' : 'Create'}</Button>{' '}
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
    );
};

export default MotherlinkEditModal;