import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Cookies from 'js-cookie';

const LoginModal = ({ isOpen, toggle, onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();
        const validUsername = 'stadmin';
        const validPassword = 'ilovefilip';

        if (username === validUsername && password === validPassword) {
            Cookies.set('username', username, { expires: 7 });
            Cookies.set('password', password, { expires: 7 });
            onLoginSuccess();
        } else {
            alert('Invalid credentials');
        }
    };

    return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Login</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleLogin}>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FormGroup>
                        <div className="d-flex justify-content-between">
                            <Button type="submit" color="primary">Login</Button>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
    );
};

export default LoginModal;