import React from "react";
import classNames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };

  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const handleLogout = () => {
    Cookies.remove('username');
    Cookies.remove('password');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
          <>
            <Navbar className={classNames("navbar-absolute", color)} expand="lg">
              <Container fluid>
                <div className="navbar-wrapper">
                  <div
                          className={classNames("navbar-toggle d-inline", {
                            toggled: props.sidebarOpened,
                          })}
                  >
                    <NavbarToggler onClick={props.toggleSidebar}>
                      <span className="navbar-toggler-bar bar1" />
                      <span className="navbar-toggler-bar bar2" />
                      <span className="navbar-toggler-bar bar3" />
                    </NavbarToggler>
                  </div>
                </div>
                <NavbarToggler onClick={toggleCollapse}>
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse navbar isOpen={collapseOpen}>
                  <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                              caret
                              color="default"
                              nav
                              onClick={(e) => e.preventDefault()}
                      >
                        <div className="photo">
                          <img alt="..." src={require("assets/img/anime3.png")} />
                        </div>
                        <b className="caret d-none d-lg-block d-xl-block" />
                        <p className="d-lg-none">Log out</p>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        <NavLink tag="li">
                          <DropdownItem className="nav-item" onClick={handleLogout}>Log out</DropdownItem>
                        </NavLink>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <li className="separator d-lg-none" />
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            <Modal
                    modalClassName="modal-search"
                    isOpen={modalSearch}
                    toggle={toggleModalSearch}
            >
              <ModalHeader>
                <Input placeholder="SEARCH" type="text" />
                <button
                        aria-label="Close"
                        className="close"
                        onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </ModalHeader>
            </Modal>
          </>
  );
}

export default AdminNavbar;