import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Input } from "reactstrap";
import ConfirmDialog from '../components/ConfirmDialog';
import MotherlinkEditModal from '../components/MotherlinkEditModal';
import '../assets/css/styles.css';
import axios from 'axios';
import { updateMotherLink } from "../components/DynuModal";

function Motherlinks() {
    const [motherlinks, setMotherlinks] = useState([]);
    const [filteredMotherlinks, setFilteredMotherlinks] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [modal, setModal] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, id: null });
    const [currentMotherlink, setCurrentMotherlink] = useState({ id: '', name: '', provider: '', url: '', email: '' });
    const [isEdit, setIsEdit] = useState(false);

    const toggleModal = () => setModal(!modal);
    const toggleConfirmDialog = () => setConfirmDialog({ ...confirmDialog, isOpen: !confirmDialog.isOpen });

    const handleSave = async (motherlink, urlChanged) => {
        if (isEdit) {
            if (urlChanged) {
                try {
                    const response = await fetch('https://vpn.intercode.se/user.php?action=find_all');
                    if (!response.ok) {
                        alert("An error occurred when trying to fetch users: " + await response.text());
                        return;
                    }
                    const users = await response.json();
                    await updateMotherLink(users, motherlink);
                } catch (error) {
                    console.error("Error in handleSave:", error);
                    alert("An error occurred: " + error.message);
                }
            }
            const updatedMotherlinks = motherlinks.map(ml => ml.id === motherlink.id ? motherlink : ml);
            setMotherlinks(updatedMotherlinks);
            setFilteredMotherlinks(updatedMotherlinks.filter(ml =>
                    Object.values(ml).some(field =>
                            field && field.toString().toLowerCase().includes(searchTerm)
                    )
            ));
        } else {
            const newMotherlinks = [...motherlinks, motherlink];
            setMotherlinks(newMotherlinks);
            setFilteredMotherlinks(newMotherlinks.filter(ml =>
                    Object.values(ml).some(field =>
                            field && field.toString().toLowerCase().includes(searchTerm)
                    )
            ));
        }
    };

    const confirmDelete = (id) => {
        setConfirmDialog({ isOpen: true, id });
    };

    const onConfirmDelete = () => {
        fetch(`https://vpn.intercode.se/motherlink.php?action=delete&id=${confirmDialog.id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setMotherlinks(motherlinks.filter(motherlink => motherlink.id !== confirmDialog.id));
                    } else {
                        console.error('Error deleting motherlink:', data.error);
                    }
                })
                .catch(error => console.error('Error deleting motherlink:', error));
        toggleConfirmDialog();
    };

    const handleRowClick = (motherlink) => {
        setCurrentMotherlink(motherlink);
        setIsEdit(true);
        toggleModal();
    };

    const fetchDataFromLinks = async () => {
        for (const motherlink of motherlinks) {
            try {
                const response = await axios.get(motherlink.url);
                const data = response.data;
                console.log('Link Name:', data.linkName || data.name);
                console.log('Quota:', data.quota || data.usage);
                break;
            } catch (error) {
                console.error('Error fetching data from link:', motherlink.url, error);
            }
        }
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        setFilteredMotherlinks(motherlinks.filter(motherlink =>
                Object.values(motherlink).some(field =>
                        field && field.toString().toLowerCase().includes(value)
                )
        ));
    };

    useEffect(() => {
        fetch('https://vpn.intercode.se/motherlink.php?action=find_all')
                .then(response => response.json())
                .then(data => {
                    setMotherlinks(data);
                    setFilteredMotherlinks(data);
                })
                .catch(error => console.error('Error fetching motherlinks:', error));
    }, []);

    return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CardTitle tag="h4">Motherlinks</CardTitle>
                                    <Button color="success" onClick={() => {
                                        setIsEdit(false);
                                        setCurrentMotherlink({ id: '', name: '', provider: '', url: '', email: '' });
                                        toggleModal();
                                    }}>+</Button>
                                </div>
                                <Input
                                        type="text"
                                        placeholder="Search motherlinks..."
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        style={{ marginTop: '10px' }}
                                />
                            </CardHeader>
                            <CardBody>
                                <div className="table-container mytable">
                                    <Table className="tablesorter">
                                        <thead className="text-primary">
                                        <tr>
                                            <th>Name</th>
                                            <th>Provider</th>
                                            <th>URL</th>
                                            <th>Email</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {filteredMotherlinks.map(motherlink => (
                                                <tr key={motherlink.id} onClick={() => handleRowClick(motherlink)}>
                                                    <td>{motherlink.name}</td>
                                                    <td>{motherlink.provider}</td>
                                                    <td>{motherlink.url}</td>
                                                    <td>{motherlink.email}</td>
                                                    <td>
                                                        <Button color="danger" className="small-trash-button"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    confirmDelete(motherlink.id);
                                                                }}>
                                                            <i className="tim-icons icon-trash-simple"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <MotherlinkEditModal
                        isOpen={modal}
                        toggle={toggleModal}
                        motherlink={currentMotherlink}
                        isEdit={isEdit}
                        onSave={handleSave}
                />

                <ConfirmDialog
                        isOpen={confirmDialog.isOpen}
                        toggle={toggleConfirmDialog}
                        onConfirm={onConfirmDelete}
                        message="Are you sure you want to delete this motherlink?"
                />
            </div>
    );
}

export default Motherlinks;