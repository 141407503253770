import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cookies from 'js-cookie';
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import LoginModal from "./LoginModal";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isAuthenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const username = Cookies.get('username');
        const password = Cookies.get('password');
        const validUsername = 'stadmin';
        const validPassword = 'ilovefilip';

        if (username === validUsername && password === validPassword) {
            setAuthenticated(true);
        } else {
            setLoginModalOpen(true);
        }
    }, []);

    const handleLoginSuccess = () => {
        setAuthenticated(true);
        setLoginModalOpen(false);
    };

    return (
            <ThemeContextWrapper>
                <BackgroundColorWrapper>
                    <BrowserRouter>
                        {isAuthenticated ? (
                                <Routes>
                                    <Route path="/*" element={<AdminLayout />} />
                                </Routes>
                        ) : (
                                <LoginModal isOpen={isLoginModalOpen} toggle={() => {}} onLoginSuccess={handleLoginSuccess} />
                        )}
                    </BrowserRouter>
                </BackgroundColorWrapper>
            </ThemeContextWrapper>
    );
};

root.render(<App />);